// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("jquery")
require('popper.js');
import 'bootstrap'

require('masonry-layout/masonry') 
// import Masonry from 'masonry-layout/masonry'
require('imagesloaded/imagesloaded')
// require("moment.kr")
// require("bootstrap-datetimepicker")
// require("select2.min")
require("carousel.min")

// require('@ckeditor/ckeditor5-build-classic')
// require('ckeditor_wave')

import "@fortawesome/fontawesome-free/js/all";

// summernote용 자바스크립트 (Bootstrap 4 버전용)
require('summernote/dist/summernote-bs4.js');
// summernote용 한국어 로케일
require('summernote/dist/lang/summernote-ko-KR.min.js');

require("ideas")


window.jQuery = window.$ = require('jquery')

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
